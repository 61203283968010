import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { FormControl } from '@angular/forms';
import {ApiClientService} from "../../api-client.service"
import { data } from 'jquery';

@Component({
  selector: 'app-step12',
  templateUrl: './step12.component.html',
  styleUrls: ['./step12.component.css']
})
export class Step12Component implements OnInit {

  nombreControl = new FormControl("");
  nifControl = new FormControl("");
  emailControl = new FormControl("");
  nifDistribuidorControl = new FormControl("");

  nombreWarning = "";
  nifWarning = "";
  emailWarning = "";
  nifDistribuidorWarning = "";

  flagDistrib = false;

  distribMsg = "";
  idBayer = 0;

  
  constructor(private router: Router, 
    private apiClient: ApiClientService) 
  { }

  ngOnInit(): void {
    this.apiClient.checkRedirect();
    this.idBayer = this.apiClient.idBayer;

    if (this.idBayer !== 0) {
      $("#nombreExplotacion").hide();
    }
  }

  next() {
    let flag = true;

    if (this.nombreControl.value == "" && this.idBayer === 0){
      this.nombreWarning = "Campo obligatorio"
      flag = false;
      
    } else {
      this.nifWarning = ""
    }


    if (this.nifControl.value == ""){
      this.nifWarning = "Campo obligatorio"
      flag = false;

    }else if (!this.validateNif(this.nifControl.value)) {
      flag = false;
      this.nifWarning = "Formato incorrecto"
    } else {
      this.nifWarning = ""
    }

    if (this.emailControl.value == ""){
      this.emailWarning = "Campo obligatorio"
      flag = false;

    }else if (!this.validateEmail(this.emailControl.value)) {
      flag = false;
      this.emailWarning = "Formato incorrecto"
    } else {
      this.emailWarning = ""
    }

    if (this.nifDistribuidorControl.value == ""){
      this.nifDistribuidorWarning = "Campo obligatorio"
      flag = false;

    }else if (!this.flagDistrib) {
      flag = false;
      this.nifDistribuidorWarning = "Cif de distribuidor inválido";
    } else {
      this.nifDistribuidorWarning = ""
    }

    if (flag) {
      this.apiClient.nombreExplotacion = this.nombreControl.value;
      this.apiClient.nif = this.nifControl.value;
      this.apiClient.email = this.emailControl.value;
      this.apiClient.nifDistribuidor = this.nifDistribuidorControl.value;

      this.router.navigate(['/step2']);
    }
  }


  validateNif(value) {
    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var nifEmprRexp = /^[TRWAGMYFPDXBNJZSQVHLCKET]{1}[0-9]{8}$/i;
    var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var str = value.toString().toUpperCase();
  
    if (!nifRexp.test(str) && !nifEmprRexp.test(str) && !nieRexp.test(str)) return false;
  
    // var nie = str
    //   .replace(/^[X]/, '0')
    //   .replace(/^[Y]/, '1')
    //   .replace(/^[Z]/, '2');
  
    // var letter = str.substr(-1);
    // var charIndex = parseInt(nie.substr(0, 8)) % 23;
  
    // if (validChars.charAt(charIndex) === letter) return true;
  
    return true;
  }

  validateEmail(valor) {
    if (/^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/.test(valor)){
     return true;
    } else {
     return false;
    }
  }

  validateNifDistribuidor(){
    const self = this;
    
    this.apiClient.busquedaNifDistribuidor(this.nifDistribuidorControl.value).subscribe({
      next(ret) {
        if (ret.data == "") {
          self.flagDistrib = false;
          self.distribMsg = "";
        } else {
          self.flagDistrib = true;
          self.distribMsg = "Este CIF corresponde a " + ret.data;
        }

      }, error(err) {

      }
    });
  }

}
