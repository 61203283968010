import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { Step1Component } from './form/step1/step1.component';
import { Step12Component } from './form/step12/step12.component';
import { Step2Component } from './form/step2/step2.component';
import { Step3Component } from './form/step3/step3.component';
import { GeneralComponent } from './layout/general/general.component';
import { EndComponent } from './form/end/end.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    Step1Component,
    Step12Component,
    Step2Component,
    Step3Component,
    GeneralComponent,
    EndComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
