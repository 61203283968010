import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { FormControl } from '@angular/forms';
import {ApiClientService} from "../../api-client.service"

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class Step3Component implements OnInit {

  option1 = new FormControl(1);
  
  cantidad1 = new FormControl(0);

  warning = " ";
  msgDosis = "";

  totalHectareas = 0;

  ahorro1 = 0;

  constructor(private router: Router, 
    private apiClient: ApiClientService) 
  { }

  ngOnInit(): void {
    this.apiClient.checkRedirect();
    
  }

  next() {
    if (this.cantidad1.value <= 0) {
        this.warning = "Debes realizar al menos una reserva";
        return;
    }


    const self = this;

    this.apiClient.cantidad1 = 0;
    this.apiClient.cantidad2 = 0;
    this.apiClient.cantidad3 = 0;


    if (this.option1.value) {
      this.apiClient["cantidad1"] = this.cantidad1.value;
    }

    this.apiClient.ahorroTotal = this.ahorro1;

    this.apiClient.guardarEntrada().subscribe({
      next(ret) {
        self.router.navigate(['/end']);
      }, error(err) {

      }
    });

  }

  update() {
    this.ahorro1 = this.cantidad1.value * this.apiClient.cantidadReserva;
  }

}
