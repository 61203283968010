<div class="container">
    <div class="row form-steps">
        <div class="col-md-3">
            1. Identifícate
        </div>
        <div class="col-md-6">
            2. ¿Cuántas hectáreas tienes?
        </div>
        <div class="col-md-3 form-step-selected">
            3. Confirma tu reserva
        </div>
    </div>
    <hr>
    <div class="row row-2">
        <div class="col-md-9">
            <div class="row">
                <div class="col-8">
                    <div class="form-group">
                        <label for="id_bayer">Producto</label>
                        <select class="form-control" [formControl]="option1" (change)="update()" id="option-1" disabled>
                            <option value="1" selected>Liberator Pack</option>
                        </select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="id_bayer">Nº Packs</label>
                        <input type="number" class="form-control" [formControl]="cantidad1" (input)="update()" id="cantidad-1">
                    </div>
                </div>
            </div>

            <div class="row mobile">
                <div class="col size-12">
                    Ahorro total: <b>{{ahorro1}}€</b>
                </div>
            </div>
        </div>
        <div class="col-3 web">
            <div class="form-group">
                <label for="id_bayer">Ahorro total</label>
                <input type="text" class="form-control" value="{{ahorro1}} €" disabled>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-md-12 warning">
            {{warning}}
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <!-- <label for="id_bayer">Email address</label> -->
                <button class="form-control btn-1" (click)="next()">Finalizar reserva</button>
            </div>
        </div>
    </div>
</div>