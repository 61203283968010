import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

   apiEndpoint = 'https://impulsobayer.es';
  // apiEndpoint = 'https://bcs.chequemotivadev.com';
  // apiEndpoint = 'http://impulsobayer.local';

  idCampania = 1;
  idPais = 1;
  cantidadReserva = 15;

  idBayer = 0;
  nif = "";
  email = "";
  nifDistribuidor = "";
  ha1 = 0;
  ha2 = 0;
  ha3 = 0;
  agriculturaVariable = "";
  dosis1 = 0;
  dosis2 = 0;
  dosis3 = 0;
  cantidad1 = 0;
  cantidad2 = 0;
  cantidad3 = 0;
  nombreExplotacion = 0;
  ahorroTotal = 0;

  accepted = false;


  constructor(private router: Router, protected http: HttpClient) { }

  checkRedirect() {
    if (!this.accepted) {
      this.router.navigate(['/step1']);
    }
  }

  sendRequest(action, params, filters = {}) {
    const url = this.apiEndpoint + action;
    let parString = '';

    let sep = '';

    params.id_pais = this.idPais;
    params.id_campania = this.idCampania;

    for (const key of Object.keys(params)) {
      parString += sep + key + '=' + params[key];
      sep = '&';
    }

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(url, parString, options);
  }

  busquedaNifDistribuidor(nif): Observable<any> {
    return this.sendRequest(
      '/externas/busquedanifdistribuidor',
      {
        nif
      }
    );
  }

  validarIdBayer(id_usuario_bayer): Observable<any> {
    return this.sendRequest(
      '/externas/validaridbayer',
      {
        id_usuario_bayer
      }
    );
  }

  obtenerCultivosPorPais(): Observable<any> {
    return this.sendRequest(
      '/externas/obtenercultivosporpais',
      {
      }
    );
  }

  guardarEntrada(): Observable<any> {
    return this.sendRequest(
      '/externas/guardarentrada',
      {
        id_usuario_bayer: this.idBayer,
        nif: this.nif,
        nif_distribuidor: this.nifDistribuidor,
        email: this.email,
        ha1: this.ha1,
        ha2: this.ha2,
        ha3: this.ha3,
        agricultura_variable: this.agriculturaVariable,
        cantidad1: this.cantidad1,
        cantidad2: this.cantidad2,
        cantidad3: this.cantidad3,
        dosis1: this.dosis1,
        dosis2: this.dosis2,
        dosis3: this.dosis3,
        nombre_explotacion: this.nombreExplotacion,
        ahorro_total: this.ahorroTotal
      }
    );
  }
}
