<div class="main text-center">
    <img src="/assets/ok.png">
    <h1>
        ¡Enhorabuena!
    </h1>
    <h2>
        Has completado tu reserva correctamente
    </h2>
    <h3>
        Recibirás un email con los datos de tu reserva y la fecha límite de compra.
    </h3>
</div>