import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { FormControl } from '@angular/forms';
import {ApiClientService} from "../../api-client.service"

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit {

  ha1       = new FormControl(0);
  ha2       = new FormControl(0);
  ha3       = new FormControl(0);
  agriculturaVariable  = new FormControl(0);

  warning = " ";
  cultivos = [];

  constructor(private router: Router,
    private apiClient: ApiClientService)
  { }

  ngOnInit(): void {
    this.apiClient.checkRedirect();

    const self = this;

    this.apiClient.obtenerCultivosPorPais().subscribe({
      next(ret) {
        self.cultivos = ret;
      }, error(err) {

      }
    });
  }

  next() {
    let flag = false;

    if (this.ha1.value > 0) {
      flag = true;
    }

    if (this.ha2.value > 0) {
      flag = true;
    }

    if (this.ha3.value > 0) {
      flag = true;
    }

    if (!flag) {
        this.warning = "Debes incluir al menos un cultivo.";
        return;
    }

    if (this.agriculturaVariable.value === 0) {
      this.warning = "Debes responder a la pregunta de sobre agricultura variable.";
      return;
    }

    this.apiClient.ha1                 = this.ha1.value;
    this.apiClient.ha2                 = this.ha2.value;
    this.apiClient.ha3                 = this.ha3.value;
    this.apiClient.agriculturaVariable = this.agriculturaVariable.value;

    this.router.navigate(['/step3']);
  }

}
