<div class="container">
    <div class="row form-steps">
        <div class="col-md-3 form-step-selected">
            1. Identifícate
        </div>
        <div class="col-md-6">
            2. ¿Cuántas hectáreas tienes?
        </div>
        <div class="col-md-3">
            3. Confirma tu reserva
        </div>
    </div>
    <hr>
    <div class="row row-2">
        <div class="col-1 text-center">
            <input id="condiciones" type="checkbox" [formControl]="checkbox" value=1>
        </div>
        <div class="col-11 condiciones">
            He leído y acepto las <a href="https://impulsobayer.es/pdf/es_bases_legales.pdf">condiciones generales</a> y
            <a href="https://impulsobayer.es/pdf/es_privacidad.pdf">política de privacidad</a> de la campaña
        </div>
        <small class="form-text allert">{{ checkboxWarning }}</small>
    </div>
    <div class="row row-2">
        <div class="col-md-6">
            <div class="form-group">
                <label for="id_bayer">ID socio Impulso Bayer</label>
                <input type="text" class="form-control" id="id_bayer" [formControl]="idBayer">
                <small id="emailHelp" class="form-text text-muted">{{idBayerWarning}}</small>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="id_bayer"></label>
                <button class="form-control btn-1" (click)="next()">Continuar con ID de Bayer</button>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-1">
            <img src="/assets/alert-triangle.png">
        </div>
        <div class="col warning">
            Si aún no estás dado de alta en Impulso Bayer puedes continuar pero tendrás que <b>darte de alta antes de terminar la campaña</b>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <!-- <label for="id_bayer">Email address</label> -->
                <button class="form-control btn-2" (click)="nextNoId()">Darme de alta más tarde</button>
            </div>
        </div>
    </div>
</div>