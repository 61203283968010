import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EndComponent } from './form/end/end.component';
import { Step1Component } from './form/step1/step1.component';
import { Step12Component } from './form/step12/step12.component';
import { Step2Component } from './form/step2/step2.component';
import { Step3Component } from './form/step3/step3.component';

const routes: Routes = [
  { path: '', redirectTo: 'step1', pathMatch: 'full'},
  { path: 'step1', component: Step1Component},
  { path: 'step12', component: Step12Component},
  { path: 'step2', component: Step2Component},
  { path: 'step3', component: Step3Component},
  { path: 'end', component: EndComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
