import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { FormControl } from '@angular/forms';
import {ApiClientService} from "../../api-client.service"

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class Step1Component implements OnInit {

  idBayer = new FormControl(0);
  checkbox = new FormControl(0);
  idBayerWarning = "";
  checkboxWarning = "";

  constructor(private router: Router, 
    private apiClient: ApiClientService) 
  { }

  ngOnInit(): void {
  }

  nextNoId() {
    if (this.checkbox.value) {
      this.apiClient.accepted = true;
      this.router.navigate(['/step12']);
    } else {
      // $(".condiciones").addClass("allert");
      
      this.checkboxWarning = "Tienes que aceptar las condiciones para seguir";
    }
  }
  
  next() {
    const self = this;

    this.apiClient.validarIdBayer(this.idBayer.value).subscribe({
      next(ret) {

        if (ret.data == 0) {
          self.idBayerWarning = "Id de Bayer incorrecto";
          $("#id_bayer").focus();
        } else {
          self.apiClient.idBayer = self.idBayer.value;
          self.nextNoId();
        }

      }, error(err) {

      }
    });
  }

}
