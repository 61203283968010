<div class="container">
    <div class="row form-steps">
        <div class="col-md-3 form-step-selected">
            1. Identifícate
        </div>
        <div class="col-md-6">
            2. ¿Cuántas hectáreas tienes?
        </div>
        <div class="col-md-3">
            3. Confirma tu reserva
        </div>
    </div>
    <hr>
    <div class="row" id="nombreExplotacion" ng-hide="idBayer !== 0">
        <div class="col-md-12">
            <div class="form-group">
                <label for="nombre">Nombre de explotación</label>
                <input type="text" class="form-control" id="nombre" [formControl]="nombreControl">
                <small class="form-text text-muted">{{nombreWarning}}</small>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-md-6">
            <div class="form-group">
                <label for="id_bayer">CIF/NIF</label>
                <input type="text" class="form-control" id="nif" [formControl]="nifControl">
                <small class="form-text text-muted">{{nifWarning}}</small>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="id_bayer">Email</label>
                <input type="text" class="form-control" id="email" [formControl]="emailControl">
                <small class="form-text text-muted">{{emailWarning}}</small>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-md-6">
            <div class="form-group">
                <label for="id_bayer">CIF de tu distribuidor</label>
                <input type="text" class="form-control" id="nif_distribuidor" (input)="validateNifDistribuidor()" [formControl]="nifDistribuidorControl">
                <small class="form-text text-muted">{{nifDistribuidorWarning}}</small>
            </div>
        </div>
        <div class="col-md-6">
            <div class="infoDistrib">
                {{ distribMsg }}
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-md-6">
            <div class="form-group">
                <!-- <label for="id_bayer">Email address</label> -->
                <button class="form-control btn-1" (click)="next()">Siguiente paso</button>
            </div>
        </div>
    </div>
</div>